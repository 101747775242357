<template>
    <search-result show-avatar class="search-result-fancy"
        v-bind:avatarUrl="image"
        v-bind:name="name">

        <template v-slot:nameSuffix>
            <span class="search-result-fancy__category">
                {{$t(`search.category.${addressType}`)}}
            </span>
        </template>

        <template v-slot:contents>
            <ui-address class="search-result-fancy__address"
                disabled hide-name
                v-bind:address="address"/>
        </template>
    </search-result>
</template>

<script>
import SearchResult from './AppSearchResult.vue';

export default {
    props: {
        image: String,
        name: String,
        addressType: String,
        address: String,
    },

    components: {
        SearchResult,
    },
};
</script>

<style lang="scss">
.search-result-fancy {
    &__category {
        display: inline-flex;
        align-items: center;
        font-weight: normal;
        opacity: .45;
        font-size: 12px;
        &::before {
            opacity: .65;
            content: '·';
            padding: 0 6px;
        }
    }
    &__address {
        margin: 0;
        font-size: 13px;
        opacity: .7;
    }
}
</style>
