<template>
    <tbody>
        <tr>
            <td class="tx-mobile-flex-content" colspan="10">
                <ui-link v-if="!hideIcon" class="tx-table-cell-icon"
                    v-bind:to="transactionRoute"
                    v-bind:title="!is_success ? $t('tx.error_icon_tooltip', [exitCode]) : undefined"
                    v-bind:class="{
                        'tx-table-cell-icon--error': (!is_success || is_bounced) && !unsuccessfulBid,
                        'tx-table-cell-icon--in': !isOut && !unsuccessfulBid,
                        'tx-table-cell-icon--out': isOut && !unsuccessfulBid,
                        'tx-table-cell-icon--blue': (is_swapped && is_success) || [
                            'subscribe',
                            'unsubscribe',
                            'jetton_mint',
                            'deploy_nft',
                        ].includes(event),
                        'tx-table-cell-icon--gray': unsuccessfulBid,
                    }">

                    <icon-tx-bounced v-if="is_bounced"/>

                    <icon-tx-error v-else-if="!is_success"/>

                    <action-icon-mobile
                        v-else-if="action || event"
                        v-bind:actionName="action?.type"
                        v-bind:eventName="event"
                        v-bind:amount="amount"
                        v-bind:isOut="isOut"/>

                    <template v-else>
                        <icon-out v-if="isOut"/>
                        <icon-in v-else/>
                    </template>
                </ui-link>

                <div v-else class="tx-table-cell-icon"
                    v-bind:to="transactionRoute"
                    v-bind:title="!is_success ? $t('tx.error_icon_tooltip', [exitCode]) : undefined"
                    v-bind:class="{
                        'tx-table-cell-icon--error': !is_success || is_bounced,
                        'tx-table-cell-icon--in': !isOut,
                        'tx-table-cell-icon--out': isOut,
                        'tx-table-cell-icon--blue': (is_swapped && is_success) || [
                            'subscribe',
                            'unsubscribe',
                            'jetton_mint',
                            'deploy_nft',
                        ].includes(event),
                    }">

                    <icon-tx-bounced v-if="is_bounced"/>

                    <icon-tx-error v-else-if="!is_success"/>

                    <action-icon-mobile
                        v-else-if="action || event"
                        v-bind:actionName="action?.type"
                        v-bind:eventName="event"
                        v-bind:amount="amount"
                        v-bind:isOut="isOut"/>

                    <template v-else>
                        <icon-out v-if="isOut"/>
                        <icon-in v-else/>
                    </template>
                </div>

                <div class="tx-mobile-content">
                    <div class="tx-mobile-content__top">
                        <!-- Top row: date and amount: -->
                        <div class="tx-mobile-content-row">
                            <!-- Date is on the left: -->
                            <ui-timeago class="tx-mobile-content__time muted"
                                v-bind:timestamp="timestamp"/>

                            <!-- Action description or amount is on the right: -->
                            <template>
                                <!-- Service actions: -->
                                <div v-if="is_service || is_external || isLog" class="muted" style="transform: translateY(50%)">
                                    {{$t(`common.empty`)}}
                                </div>

                                <!-- tonscan api action badge: -->
                                <action-badge-mobile
                                    v-else-if="action"
                                    v-bind:action="action"
                                    v-bind:amount="amount"
                                    v-bind:is_out="isOut"
                                    v-bind:from="from"
                                    v-bind:meta="meta"
                                    v-bind:to="to"
                                    v-bind:event="event"/>

                                <div v-else-if="[
                                         'jetton_burn',
                                         'jetton_mint',
                                         'auction_bid',
                                     ].includes(event)"
                                    v-bind:class="{
                                        'tx-amount--in': !isOut && !unsuccessfulBid,
                                        'tx-amount--out': isOut && !unsuccessfulBid,
                                        'muted': unsuccessfulBid,
                                    }">
                                    {{$ton(amount, meta?.decimals ?? 9)}} {{meta?.jetton?.symbol || meta?.symbol}}
                                </div>

                                <!-- tonapi events without TON amount: -->
                                <div class="muted" style="transform: translateY(50%)"
                                    v-else-if="[
                                        'unsubscribe',
                                        'external',
                                        'contract_deploy',
                                        'withdraw_stake_request',
                                        'domain_renew',
                                    ].includes(event)">
                                    {{$t(`events.${event}`)}}
                                </div>

                                <!-- TON transfers: -->
                                <div v-else v-bind:class="{
                                    'tx-amount--in': !isOut,
                                    'tx-amount--out': isOut,
                                }">{{$ton(amount)}} TON</div> <!-- No line breaks to avoid whitespaces -->
                            </template>
                        </div>

                        <!-- Bottom row: address and action: -->
                        <div class="tx-mobile-content-row">
                            <!-- Transaction account is on the left: -->
                            <template>
                                <span v-if="is_external || event === 'external'" class="muted">
                                    {{$t('address.tx_table.external')}}
                                </span>

                                <ui-link v-else-if="is_aggregated" v-bind:to="transactionRoute">
                                    {{$tc('address.tx_table.address_count', outputCount)}}
                                </ui-link>

                                <template v-else-if="isLog">
                                    {{$t('address.tx_table.nowhere')}}
                                </template>

                                <ui-address v-else-if="!isOut"
                                    v-bind:address="sender"
                                    v-bind:type="source_type"
                                    v-bind:alias="source_alias"/>

                                <ui-address v-else
                                    v-bind:address="receiver"
                                    v-bind:type="destination_type"
                                    v-bind:alias="destination_alias"/>
                            </template>

                            <!-- Transaction action is on the right: -->
                            <div class="muted">
                                <!-- tonscan api actions: -->
                                <template v-if="action">
                                    <span v-if="action.type.endsWith(':excesses')">
                                        Excess
                                    </span>

                                    <!-- nominator pool actions: -->
                                    <template v-else-if="action.type.startsWith('pool:')">
                                        <template v-if="action.type === 'pool:recover_stake'">
                                            Recover stake
                                        </template>

                                        <template v-else-if="action.type === 'pool:recover_stake_ok'">
                                            Stake recovered
                                        </template>

                                        <template v-else-if="action.type === 'pool:new_stake'">
                                            <template v-if="amount > 5000000000">
                                                Add stake
                                            </template>
                                            <template v-else>
                                                Push stake
                                            </template>
                                        </template>

                                        <template v-else-if="action.type === 'pool:update_validator_set_hash'">
                                            Update validator
                                        </template>

                                        <template v-else-if="action.type === 'pool:process_withdraw_requests'">
                                            Process withdraws
                                        </template>
                                    </template>

                                    <!-- jetton actions: -->
                                    <template
                                        v-else-if="[
                                            'jetton:burn',
                                            'jetton:burn_notification',
                                            'jetton:transfer',
                                            'jetton:transfer_notification',
                                            'jetton:internal_transfer',
                                            'jetton:swap',
                                            'jetton:route_tonapi',
                                        ].includes(action.type)">

                                        <span
                                            v-if="action.type === 'jetton:burn' || action.type === 'jetton:burn_notification'">
                                            {{$t('events.jetton_burn')}}
                                        </span>

                                        <span
                                            v-else-if="action.type === 'jetton:swap'"
                                            class="mobile-swap--green">
                                            + {{$ton(meta.amount_out, meta.decimals_out, true)}} {{meta.symbol_out}}
                                        </span>

                                        <span
                                            v-else-if="action.type === 'jetton:transfer'">
                                            {{$t('events.transferred')}}
                                        </span>

                                        <span
                                            v-else-if="action.type === 'jetton:transfer_notification'">
                                            {{$t('events.received')}}
                                        </span>

                                        <template v-else>
                                            <span v-if="is_out">
                                                {{$t('events.transferred')}}
                                            </span>
                                            <span v-else>
                                                {{$t('events.received')}}
                                            </span>
                                        </template>
                                    </template>
                                </template>

                                <!-- tonapi events: -->
                                <template
                                    v-else-if="[
                                        'subscribe',
                                        'deposite_stake',
                                        'elections_recover_stake',
                                        'elections_deposit_stake',
                                        'smart_contract_exec',
                                        'withdraw_stake',
                                        'jetton_mint',
                                        'auction_bid',
                                        'jetton_route',
                                    ].includes(event)">
                                    {{$t(`events.${event}`)}}
                                </template>

                                <template v-else-if="showFiatValue">
                                    <span style="margin-left: 10px;">≈ <ui-fiat v-bind:tonValue="amount"/></span>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div v-if="message || op === '0x2167da4b'" class="tx-mobile-content__bottom">
                        <!-- Encrypted message: -->
                        <template v-if="op === '0x2167da4b'">
                            <div class="tx-mobile-comment">
                                <span class="tx-mobile-comment__contents muted" v-text="$t('tx.encrypted_message')"/>
                            </div>
                        </template>

                        <!-- Transaction comment: -->
                        <template v-else-if="message">
                            <div class="tx-mobile-comment" v-on:click="showMore">
                                <span class="tx-mobile-comment__contents"
                                    v-bind:class="{ 'tx-mobile-comment__contents--truncated': isTruncated }"
                                    v-text="message"/>

                                <button class="tx-mobile-comment__expander"
                                    v-if="isTruncated"
                                    v-text="$t('address.tx_table.show_more')"/>
                            </div>
                        </template>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { isSameAddress } from '~/tonweb.js';
import IconTxError from '@img/icons/tonscan/transactions/tx-failed.svg?vue';
import IconTxBounced from '@img/icons/tonscan/transactions/tx-excess.svg?vue';
import IconIn from '@img/icons/tonscan/transactions/tx-in.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import ActionIconMobile from './TxRowActionIconMobile.vue';
import ActionBadgeMobile from './TxRowActionBadgeMobile.vue';

export default {
    props: {
        address: String,
        date: String,
        from: String,
        is_service: Boolean,
        is_external: Boolean,
        is_aggregated: Boolean,
        is_success: Boolean,
        is_bounced: Boolean,
        is_swapped: Boolean,
        outputCount: Number,
        to: String,
        amount: String,
        message: String,
        timestamp: Number,
        txHash: String,
        exitCode: Number,
        op: [Number, String],
        source_type: String,
        source_alias: String,
        destination_type: String,
        destination_alias: String,
        action: Object,
        event: String,
        source: String,

        meta: {
            type: Object,
            default: () => ({}),
        },

        messageMaxChars: {
            type: Number,
            default: 200,
        },
        hideIcon: Boolean,
    },

    data() {
        return {
            isTruncated: false,
        };
    },

    computed: {
        showFiatValue() {
            return !this.action
                && !this.is_service
                && !this.is_external
                && !this.isLog
                && this.amount > 0
                && this.event !== 'withdraw_stake_request';
        },

        sender() {
            switch (this.action?.type) {
                case 'jetton:transfer_notification': {
                    return this.action.sender;
                }

                case 'nft:ownership_assigned': {
                    return this.action.prev_owner;
                }

                default: {
                    return this.from;
                }
            }
        },

        receiver() {
            switch (this.action?.type) {
                case 'jetton:transfer': {
                    return this.action.destination;
                }

                case 'nft:transfer': {
                    return this.action.new_owner;
                }

                default: {
                    return this.to;
                }
            }
        },

        isLog() {
            return !this.to;
        },

        isOut() {
            return isSameAddress(this.from, this.address);
        },

        transactionRoute() {
            return {
                name: 'tx',
                params: {
                    hash: this.txHash,
                },
            };
        },

        unsuccessfulBid() {
            return !this.is_success && this.event === 'auction_bid';
        },
    },

    created() {
        this.isTruncated = this.message && (this.message.length > this.messageMaxChars);
    },

    methods: {
        showMore() {
            this.isTruncated = false;
        },
    },

    components: {
        IconTxError, IconTxBounced, IconIn, IconOut, ActionIconMobile, ActionBadgeMobile,
    },
};
</script>
