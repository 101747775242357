<template>
    <div class="ads-page-screen">
        <p class="ads-page-screen__header">{{ $t('ads_page.screen.header') }}</p>
        <p class="ads-page-screen__text">{{ $t('ads_page.screen.text') }}</p>
        <a href="https://t.me/adiutor" target="_blank" class="ads-page-screen__button">{{ $t('ads_page.contact_us') }}</a>
        <transition v-if="!isMobile && !isTablet" name="fade-screen">
            <img v-show="gradientBgLoaded"
                v-bind:src="gradientBg"
                v-on:load="gradientBgLoaded = true"
                class="ads-page-screen__image"
            />
        </transition>
        <transition name="fade-grid">
            <img v-show="gridLoaded"
                v-bind:src="maskGrid"
                v-on:load="gridLoaded = true"
                class="ads-page-screen__image--grid" />
        </transition>
    </div>
</template>

<script>
import gradientBg from '@img/ads-page-banner.png';
import maskGrid from '@img/mask-grid.png';

export default {
    data() {
        return {
            gradientBg,
            maskGrid,
            gradientBgLoaded: false,
            gridLoaded: false,
        };
    },
};
</script>

<style scoped lang="scss">
.ads-page-screen {
    position: relative;
    width: 100%;
    padding: 6dvh 40px;
    box-sizing: border-box;
    background: var(--advertise-page-screen-gradient);
    border-radius: 12px;
    margin-bottom: 18px;
    color: white;
    overflow: hidden;

    &__header {
        margin: 0;
        font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
        font-weight: 800;
        font-size: 32px;
    }

    &__text {
        max-width: 660px;
        margin-bottom: 20px;
    }

    &__button {
        display: inline-block;
        width: auto;
        text-align: center;
        border: none;
        border-radius: 8px;
        color: #FFF;
        background-color: #ffffff35;
        padding: 10px 18px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        z-index: 101;

        &:hover {
            text-decoration: none;
            background-color: #ffffff41;
        }
    }

    &__image {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 99;

        &--grid {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 98;
        }
    }
}

@media screen and (max-width: 768px){
    .ads-page-screen {
        width: calc(100% + 16px);
        margin-left: -8px;
        margin-top: -18px;
        border-radius: 0;
        padding: 6dvh 20px 4dvh 20px;

        &__button {
            box-sizing: border-box;
            width: 100%;
        }

        &__header, &__text {
            text-align: center;
        }
    }
}

.fade-screen-enter, .fade-screen-leave-to {
    opacity: 0;
    transform: translateX(10px);
}
.fade-screen-enter-active, .fade-screen-leave-active {
    transition: opacity 2.8s, transform 1.4s;
}

.fade-grid-enter, .fade-grid-leave-to {
    opacity: 0;
}
.fade-grid-enter-active, .fade-grid-leave-active {
    transition: opacity 2.8s;
}
</style>
