<template>
    <section>
        <div v-if="emptyHistory" class="tx-history-empty-panel">
            {{ $t('address.tokens.no_tokens') }}
        </div>

        <div v-else class="tx-history-wrap">
            <table v-bind:class="isMobile
                ? 'ui-table ui-table-mobile'
                : 'ui-table'">
                <thead v-if="!isMobile">
                    <tr>
                        <th width="200">{{ $t('address.tokens.table_token_name') }}</th>
                        <th width="300" style="max-width: 300px;">{{ $t('address.tokens.table_token_balance') }}</th>
                        <th class="ui-table__cell--align-right">{{ $t('address.tokens.table_token_jetton_wallet') }}</th>
                        <!-- <th class="ui-table__cell--align-right">{{ $t('address.tokens.table_token_amount') }}</th> -->
                    </tr>
                </thead>
                <tbody v-if="isLoading">
                    <component
                        v-for="i in 8"
                        v-bind:is="isMobile
                            ? 'tab-token-skeleton-mobile'
                            : 'tab-token-skeleton'"
                        v-bind:key="`tx_skeleton_${i}`"
                    />
                </tbody>
                <tbody v-else>
                    <!-- <component
                        v-for="wallet in jettons"
                        v-bind:key="wallet.address"
                        v-bind:is="isMobile
                            ? 'tab-token-item-mobile'
                            : 'tab-token-item'"
                        v-bind:wallet="wallet"
                        v-bind:rates="rates"
                    /> -->
                    <component
                        v-for="wallet in sortedJettons"
                        v-bind:key="wallet.address"
                        v-bind:is="isMobile ? 'tab-token-item-mobile' : 'tab-token-item'"
                        v-bind:wallet="wallet"
                        v-bind:rates="rates"
                    />
                </tbody>
            </table>
        </div>

        <div v-if="emptyJettons.length > 0 && !emptyJettonsVisible" class="block-empty-jettons" v-on:click="emptyJettonsVisible = true">
            {{ $t('address.tokens.show_empty_tokens', [emptyJettons.length]) }}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" d="m1.5 4.75 5.5 5.5 5.5-5.5"/></svg>
        </div>

        <div v-if="emptyJettonsVisible" class="tx-history-wrap">
            <table class="ui-table">
                <tr v-for="wallet in emptyJettons" v-bind:key="wallet.address">
                    <td>
                        <ui-link v-bind:to="{ name: 'jetton', params: { address: wallet.jetton_address }}" class="jetton-list-link">
                            <ui-round-image class="jetton-list__icon" size="tiny" v-bind:src="wallet.jetton_meta.image.w72"/>
                            {{ wallet.jetton_meta.name || wallet.jetton_address }}
                        </ui-link>
                    </td>
                    <td>
                        <b>{{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}</b>
                        <span style="margin-left: 4px; opacity: .5">{{ wallet.jetton_meta.symbol }}</span>
                    </td>
                    <td class="ui-table__cell--align-right">
                        <ui-address v-bind:address="wallet.address"/>
                    </td>
                </tr>
            </table>
        </div>
    </section>
</template>

<script>
import { formatTons } from '~/helpers.js';
import { getJettonBalances as getJettonWalletsTonapi } from '~/api/tonapi.js';
import { getJettons } from '~/api/jettoncat';
import TabTokenSkeleton from './TabTokenSkeleton.vue';
import TabTokenSkeletonMobile from './TabTokenSkeletonMobile.vue';
import TabTokenItem from './TabTokenItem.vue';
import TabTokenItemMobile from './TabTokenItemMobile.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            jettons: [],
            emptyJettons: [],
            emptyJettonsVisible: false,
            isLoading: true,
            emptyHistory: false,
            rates: null,
        };
    },
    created() {
        this.loadData();
    },
    computed: {
        sortedJettons() {
            return this.jettons.slice().sort((a, b) => {
                const rateA = this.getJettonRate(a) || 0;
                const rateB = this.getJettonRate(b) || 0;
                return rateB - rateA;
            });
        },
    },
    methods: {
        getJettonRate(wallet) {
            const jettonsCount = parseFloat(formatTons(wallet.balance, wallet.jetton_meta.decimals).replace(/\s/g, '').replace(',', '.'));

            const rate = this.rates.filter(({ id }) => id === wallet.jetton_address);

            if (rate.length > 0) {
                return rate[0].price * jettonsCount;
            }

            return null;
        },
        async loadData() {
            try {
                const jettons = await getJettonWalletsTonapi(this.address);
                this.jettons = jettons;

                this.rates = await getJettons({ limit: 100 });

                this.emptyHistory = jettons.length === 0;
            } catch (error) {
                console.error('Error loading jettons:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },

    components: {
        TabTokenSkeleton,
        TabTokenSkeletonMobile,
        TabTokenItem,
        TabTokenItemMobile,
    },
};
</script>

<style lang="scss">
.jetton-list__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    &--rastopyrka {
        visibility: hidden;
        height: 0;
    }
}

.jetton-list-link {
    display: flex;
    align-items: center;
}

.tx-token-cell {
    display: flex;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
}

.ui-table-mobile {
    table-layout: fixed;
}

.ui-table-mobile td {
    padding: 0!important;
    border-top: 0!important;
}

.ui-table-mobile .tx-mobile-content {
    border-bottom: 1px solid var(--card-row-separator);
    padding: 1em 14px 3px 5px;
}

.ui-table-mobile tr:last-child .tx-mobile-content {
    border-bottom: none;
}
</style>
