<template>
    <div class="minex-block-mobile-container">
        <div v-on:click="openAdLink" class="minex-block-mobile">
            <div class="minex-block-mobile__header">
                {{ $t('ads.minex.title') }} <ui-link v-bind:to="{ name: 'advertise' }" class="minex-block-mobile__ads-tag" v-on:click.stop>{{ $t('ads.sponsored') }}</ui-link>
            </div>
            <div class="minex-block-mobile__description">
                {{ $t('ads.minex.description_mobile') }}
            </div>
            <banner-url class="minex-img-mobile" />
            <img class="minex-img-mobile--grid" v-bind:src="maskGridUrl" alt="MineX" />
        </div>
    </div>
</template>

<script>
import bannerUrl from '@img/minex-ad.svg?vue'; // eslint-disable-line
import maskGridUrl from '@img/mask-grid.png';

export default {
    data() {
        return {
            maskGridUrl,
        };
    },

    methods: {
        openAdLink() {
            window.open('https://t.me/MineXton_bot?start=r_172498493', '_blank');
        },
    },

    components: {
        bannerUrl,
    },
};
</script>

<style lang="scss">
.minex-block-mobile-container {
    padding: 0 16px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 16px;
}

.minex-block-mobile {
    display: block;
    background: linear-gradient(to right, #1E8DFF, #125599);
    font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
    color: white;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    font-size: 14px;
    overflow: hidden;
    padding: 10px 16px;
    cursor: pointer;

    &__header {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    &__description {
        max-width: 260px;
        z-index: 100;
    }

    &__ads-tag {
        display: inline-block;
        transform: translate(3px, 2px);
        margin-left: 2px;
        font-size: 10px;
        padding: 1px 7px;
        background: var(--card-pill-background);
        border-radius: 10px;
        font-weight: 800;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.19);
        color: white;
        z-index: 99;
        position: absolute;
        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.minex-img-mobile {
    position: absolute;
    width: 116px;
    height: auto;
    top: -8px;
    right: -10px;
    z-index: 9;

    &--grid {
        position: absolute;
        width: auto;
        height: 200px;
        top: 0;
        right: 0;
        z-index: 8;
    }
}

@media screen and (max-width: 395px) {
    .minex-img-mobile {
        width: 95px;
        top: 2px;
        right: -10px;
    }
}

.minex-mobile-arrow-right {
    width: auto;
    height: 18px;
    margin: 0 0 -4px -7px;
}
</style>
