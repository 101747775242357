<template>
    <tbody>
        <tr>
            <td>
                <ui-link class="tx-table-cell-icon" v-bind:to="transactionRoute">
                    <icon-tx-chain v-once fill="none"/>
                </ui-link>
            </td>
            <td>
                <span class="tx-table__cell">
                    <ui-timeago v-bind:timestamp="timestamp"/>
                </span>
            </td>
            <td>
                <span class="tx-table__cell">
                    <span class="jetton-history-pair__left">
                        <span class="ui-inline-jetton__value">
                            {{ $ton(from.amount, 0, !(from.amount < 0.01)) }}
                        </span>
                        <ui-link v-if="from?.symbol && from?.address !== jettonAddress" v-bind:to="{ name: 'jetton', params: {address: from.address }}">
                            {{ from.symbol }}
                        </ui-link>
                        <template v-else>{{ from?.symbol || 'TON'}}</template>
                    </span>
                    <span class="tx-table-action-icon">
                        <arrow-right />
                    </span>
                    <span class="jetton-history-pair__right">
                        <span class="ui-inline-jetton__value">
                            {{ $ton(to.amount, 0, !(to.amount < 0.01)) }}
                        </span>
                        <ui-link v-if="to?.symbol && to?.address !== jettonAddress" v-bind:to="{ name: 'jetton', params: {address: to.address }}">
                            {{ to.symbol }}
                        </ui-link>
                        <template v-else>{{ to?.symbol || 'TON'}}</template>
                    </span>
                </span>
            </td>
            <td>
                <span class="tx-table__cell tx-table__cell--align-right">
                    <ui-address v-bind:address="initiator" />
                </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import IconTxChain from '@img/icons/tonscan/chain-16.svg?vue';
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';

export default {
    props: {
        hash: String,
        timestamp: Number,
        from: Object,
        to: Object,
        initiator: String,
        type: String,
        jettonAddress: String,
    },

    computed: {
        transactionRoute() {
            return { name: 'tx', params: { hash: this.hash } };
        },
    },

    components: {
        IconTxChain,
        ArrowRight,
    },
};
</script>

<style scoped lang="scss">
.jetton-history-pair {
    &__left {
        color: var(--badge-red-color);

        a {
            color: var(--badge-red-color);
        }
    }
    &__right {
        color: var(--badge-green-color);

        a {
            color: var(--badge-green-color);
        }
    }
}
</style>
