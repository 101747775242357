var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',{staticStyle:{"position":"relative"}},[_c('tr',{staticClass:"new-row-animation event-overview-row"},[_c('td',{staticClass:"tx-mobile-flex-content"},[(_vm.type === 'buy')?_c('ui-link',{staticClass:"tx-table-cell-icon tx-table-cell-icon--in",attrs:{"to":_vm.transactionRoute}},[_c('icon-in')],1):_vm._e(),_vm._v(" "),(_vm.type === 'sell')?_c('ui-link',{staticClass:"tx-table-cell-icon tx-table-cell-icon--out",attrs:{"to":_vm.transactionRoute}},[_c('icon-out')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tx-mobile-content"},[_c('div',{staticClass:"tx-block-mobile-content__wrapper tx-block-mobile-content-swap-wrapper"},[_c('div',{staticClass:"tx-block-mobile-content__row tx-block-mobile-swap-items"},[_c('div',{staticClass:"tx-block-mobile-content__left"},[_c('ui-timeago',{staticClass:"muted",attrs:{"timestamp":_vm.timestamp}})],1),_vm._v(" "),_c('div',{staticClass:"tx-block-mobile-content__right mobile-swap--red"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v("\n                                − "+_vm._s(_vm.$ton(_vm.from.amount, 0, !(_vm.from.amount < 0.01)))+"\n                            ")]),_vm._v(" "),(_vm.from?.symbol)?_c('ui-link',{staticClass:"router-link__no-style",attrs:{"to":{
                                    name: 'jetton',
                                    params: { address: _vm.from.address },
                                }}},[_vm._v("\n                                "+_vm._s(_vm.from.symbol)+"\n                            ")]):[_vm._v("TON")]],2)]),_vm._v(" "),_c('div',{staticClass:"tx-block-mobile-content__row"},[_c('div',{staticClass:"tx-block-mobile-content__left"},[_c('ui-address',{staticStyle:{"max-width":"160px"},attrs:{"address":_vm.initiator}})],1),_vm._v(" "),_c('div',{staticClass:"tx-block-mobile-content__right mobile-swap--green"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v("\n                                + "+_vm._s(_vm.$ton(_vm.to.amount, 0, !(_vm.to.amount < 0.01)))+"\n                            ")]),_vm._v(" "),(_vm.to?.symbol)?_c('ui-link',{staticClass:"router-link__no-style",attrs:{"to":{
                                    name: 'jetton',
                                    params: { address: _vm.to.address },
                                }}},[_vm._v("\n                                "+_vm._s(_vm.to.symbol)+"\n                            ")]):[_vm._v("TON")]],2)])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }