<template>
    <div>
        <div class="widget-content widget-channel-container">
            <top-jetton-skeleton v-if="!jettonsData" v-bind:rows="limit" />
            <div class="widget-jettons" v-else>
                <top-jetton-item
                    v-for="(jetton, index) in jettonsData"
                    v-bind:key="index"
                    v-bind:img="jetton.img"
                    v-bind:name="jetton.name"
                    v-bind:price="jetton.price"
                    v-bind:symbol="jetton.symbol"
                    v-bind:jetton="jetton.jetton"
                    v-bind:change="jetton.priceChange24h"
                />
            </div>
        </div>
        <div class="mobile-tab-btn__container">
            <ui-link v-bind:to="{ name: 'market' }" class="mobile-tab-btn">
                {{ $t('indexpage.mobile.show_all') }}
            </ui-link>
        </div>
        <div class="dyor-logo-widget muted">
            <span v-html="$t('jetton_filters.dyor.data_source' )" />
        </div>
    </div>
</template>

<script>
import { getTopMCJettonsList } from '~/api/dyor.js';
import TopJettonItem from '../../TopJettons/TopJettonItem.vue';
import TopJettonSkeleton from '../../TopJettons/TopJettonItemSkeleton.vue';

export default {
    data() {
        return {
            jettonsData: undefined,
            limit: 9,
        };
    },

    mounted() {
        this.getTrendingInfo();
    },

    methods: {
        async getTrendingInfo() {
            try {
                this.jettonsData = await getTopMCJettonsList(this.limit);
            } catch (error) {
                console.error(error);
            }
        },
    },

    components: {
        TopJettonItem,
        TopJettonSkeleton,
    },
};
</script>

<style lang="scss">
.widget-jettons {
    padding: 0 0 10px 0;
}

.widget-jettons-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    margin: 8px 10px;
    border-radius: 10px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.widget-jettons a:hover {
    background: var(--body-light-muted-color);
}

@media screen and (max-width: 599px) {
    .widget-jettons > .widget-jettons-item {
        margin-left: 0;
        margin-right: 0;
    }
}

.widget-jettons-item:last-child {
    margin-bottom: 0;
}

.widget-jettons-item:hover {
    text-decoration: none;
}

.widget-jettons-content {
    display: flex;
    align-items: center;
    .widget-jettons-name {
        font-size: 14px;
        color: var(--body-text-color);
        font-weight: 500;
    }
}

.widget-jettons-data {
    display: flex;
    align-items: center;
}

.widget-jettons-price {
    font-weight: 500;
    margin-left: 20px;
    color: var(--body-text-color);
}

.widget-jettons-img {
    width: 24px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 10px;
}

.widget-jettons-img img {
    width: 100%;
    vertical-align: top;
}

.dyor-logo-widget {
    padding-bottom: 10px;
    text-align: center;
    font-size: 14px;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--blue-bright);
        }
    }

    &__icon {
        width: 15px;
        height: 15px;
        fill: currentColor;
        transform: translateY(3px);
        margin-left: 2px;
    }
}
</style>
