<template>
    <ui-link v-bind:to="{ name: 'jetton', params: { address: jetton }}" class="card-market-jetton">
        <div class="card-market-jetton-item">
            <div class="card-market-jetton-image">
                <ui-round-image class="widget-jettons-icon" size="tiny" v-bind:src="img" />
            </div>
            <div class="card-market-jetton-name">
                <span>{{ symbol }}</span>
            </div>
        </div>
        <div class="card-market-jetton-item">
            <div class="card-market-jetton-price">
                <ui-format-price
                    v-bind:price="price"
                />
            </div>
            <trending-percent-change v-bind:change="change" />
        </div>
    </ui-link>
</template>

<script>
import TrendingPercentChange from '../../PercentChange.vue';

export default {
    props: {
        img: {
            type: String,
        },
        name: {
            type: String,
        },
        price: {
            type: String,
        },
        symbol: {
            type: String,
        },
        jetton: {
            type: String,
        },
        change: {
            type: String,
        },
    },
    data() {
        return {

        };
    },
    components: {
        TrendingPercentChange,
    },
};
</script>
