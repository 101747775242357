<template>
    <div class="widget-jettons">
        <div class="widget-jettons-item" v-for="r in rows" v-bind:key="r">
            <div class="widget-jettons-content">
                <div class="widget-jettons-img">
                    <div class="skeleton">NN</div>
                </div>
                <div class="widget-jettons-name">
                    <div class="skeleton">NNNNNNNNNNN</div>
                </div>
            </div>
            <div class="widget-jettons-data">
                <div class="skeleton">NNNN</div>
                <div class="widget-jettons-price">
                    <div class="skeleton">NNNNN</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rows: {
            type: Number,
            default: 3,
        },
    },
};
</script>

<style lang="scss">
.widget-jettons-img {
    width: 24px;
    height: 24px;
}
.widget-jettons-img .skeleton {
    height: 100%;
}
</style>
