<template>
    <div class="widget minex-block">
        <div class="minex-grid">
            <div>
                <ui-link v-bind:to="{ name: 'advertise' }" class="minex-block__sponsored-tag">
                    {{ $t('ads.sponsored') }}
                </ui-link>
                <div class="minex-block__header">
                    {{ $t('ads.minex.title') }}
                </div>
                <div class="minex-block__description" v-html="$t('ads.minex.description')" />
            </div>
            <div>
                <banner-url class="minex-img" />
                <img class="minex-img--grid" v-bind:src="maskGridUrl" alt="MineX" />
            </div>
        </div>
        <a href="https://t.me/MineXton_bot?start=r_172498493" target="_blank" class="minex-button">{{ $t('ads.minex.button') }}</a>
    </div>
</template>

<script>
import bannerUrl from '@img/minex-ad.svg?vue'; // eslint-disable-line
import maskGridUrl from '@img/mask-grid.png';

export default {
    data() {
        return {
            maskGridUrl,
        };
    },
    components: {
        bannerUrl,
    },
};
</script>

<style lang="scss">
.minex-block {
    border: none!important;
    background: linear-gradient(to right, #1E8DFF, #125599);
    font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
    padding: 10px 20px 16px 20px;
    color: white;
    position: relative;
    min-width: 0;
    box-sizing: border-box;

    &__sponsored-tag {
        font-size: 12px;
        font-weight: 800;
        color: white;
        background-color: hsla(0, 0%, 100%, .19);
        display: inline-block;
        border-radius: 5px;
        padding: 2px 6px;
        margin-bottom: 7px;
        text-transform: uppercase;

        &:hover {
            background-color: hsla(0, 0%, 100%, .25);
            text-decoration: none;
        }
    }

    &__header {
        font-weight: 800;
        font-size: 22px;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    &__description {
        b {
            font-weight: 800;
        }
    }
}

.minex-grid {
    display: grid;
    grid-template-columns: 220px auto;
}

@media screen and (max-width: 1170px) {
    .minex-grid {
        grid-template-columns: 400px auto;
        margin-top: 10px;
    }
}

.minex-button {
    display: block;
    text-align: center;
    width: 100%;
    background: linear-gradient(to top, #d3e9ff, #ffffff);
    padding: 10px 0;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 15px;
    text-transform: uppercase;
    color: #2091ff;
    font-weight: 500;
    font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
    cursor: pointer;
    position: relative;
    z-index: 100;

    &:hover {
        background: #f5f9ff;
        text-decoration: none;
    }
}

.minex-img {
    position: absolute;
    width: 190px;
    height: auto;
    top: -12px;
    right: -20px;
    z-index: 9;

    &--grid {
        position: absolute;
        width: auto;
        height: 200px;
        top: 0;
        right: 0;
        z-index: 8;
    }
}

@media screen and (max-width: 1170px) {
    .minex-img {
        width: 180px;
    }
}
</style>
